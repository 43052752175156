import Alpine from 'alpinejs';
import FormHandler, { GoogleV2 } from '../grit-form-handler';
import { msg } from '../main';

export const INIT_forms = () => {
  const gRecaptchaSiteKey = document
    .querySelector("meta[name='google_recaptcha_site_key']")
    .getAttribute('content');
  const forms = [...document.querySelectorAll('[data-form-handler]')];

  if (forms) {
    forms.forEach(form => {
      const dataAlerts = JSON.parse(form.dataset.formAlerts);

      new FormHandler(`#${form.id}`, {
        recaptcha: new GoogleV2(gRecaptchaSiteKey),
        flashMessage: msg,
        localisation: {
          success: dataAlerts.success
            ? dataAlerts.success
            : 'Thank you for your submission.',
          error: dataAlerts.error
            ? dataAlerts.error
            : 'There was an error with your submission.',
        },
        scrollToError: {
          enabled: true,
          offset: document.querySelector('header.g-header').offsetHeight + 48,
        },
        validate: {
          onChange: true,
          onSubmit: true,
        },
        disableSubmit: true,
        onSuccess: (form, res) => {
          // console.log('Success', form, res);

          if (form.name === 'get_in_touch') {
            window.Alpine = Alpine;

            Alpine.store('getInTouch', {
              formStep: 1,
            });

            document.getElementById('get-in-touch').classList.remove('is-open');
          }

          if (form.name === 'payment') {
            location.reload();
          } else {
            msg.flash(dataAlerts.success, true);
          }

          // GTM Event
          let gtmFields = {};
          const fields = [
            ...form.querySelectorAll('[data-gtm-form-interact-field-id]'),
          ];

          if (fields.length) {
            fields.forEach(field => {
              gtmFields[field.name] = field.value;
            });
          }

          dataLayer.push({
            event: 'form.submit.success',
            form: {
              id: form.id, //id attribute
              name: form.name, //form name, from attirbute or identifiable purpose
              provider: 'GRIT', //eg. CF7, GRIT, hubspot etc.
              fields: gtmFields,
            },
          });
        },
        onError: (form, res) => {
          // console.log('Error', form, res);
          msg.flash(dataAlerts.error, false);

          if (form.name === 'get_in_touch') {
            if (
              res.errors.hasOwnProperty('email') ||
              res.errors.hasOwnProperty('full_name') ||
              res.errors.hasOwnProperty('telephone') ||
              res.errors.hasOwnProperty('enquiry_type')
            ) {
              window.Alpine = Alpine;

              Alpine.store('getInTouch', {
                formStep: 1,
              });
            }
          }

          if (form.name === 'payment') {
            document.querySelector(
              '#form_payment button[type="submit"]'
            ).disabled = false;
          }
        },
      });
    });
  }

  // In-field labels
  window.addEventListener('keyup', event => {
    const target = event.target;

    if (target.dataset.infield) {
      target.setAttribute('value', target.value);
    }
  });

  // Disable submit button
  // if (
  //   typeof document.querySelector('#form_payment') != 'undefined' &&
  //   document.querySelector('#form_payment') != null
  // ) {
  //   document.querySelector('#form_payment').addEventListener('submit', () => {
  //     const target = document.querySelector(
  //       '#form_payment button[type="submit"]'
  //     );

  //     target.disabled = true;
  //   });
  // }
};
